import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/mdxLayouts/PostsLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`What is Tailwind CSS?`}</h2>
    <p>{`Tailwind is a CSS library that adds utility classes similar to bootstrap. The difference primarily comes at build time where tailwind won't ship all of the classes in its library, but only those that are needed for your project. All of the classes are essentially shorthand for CSS properties. If I wanted a margin-bottom of 1rem I could write out the whole line in my CSS class or I can add "mb-4" with Tailwind. This makes development a lot easier to keep consistent spacing, font sizes, and container widths.`}</p>
    <h2>{`Why not just plain CSS?`}</h2>
    <p>{`This is totally up to you. If you already have a fast workflow with plain CSS try out Tailwinds for a project and see if it works with your development style. I enjoy coding out my project one component at a time and focusing on several windows to manage what needs what and where can get very disorienting for me.`}</p>
    <h2>{`What about Dark Mode?`}</h2>
    <p>{`One of the huge upsides to Tailwinds for me personally is the addition of dark mode classes. For a while now I have wanted to set up my own dark mode flow but everytime I start I get discouraged by just how many ways you can go about it and the problems that come with implementing this feature yourself. Tailwinds has fantastic support for this and makes adding dark mode to any site a breeze. I have added a quick snippet that I use to toggle dark mode on my site.`}</p>
    <pre {...{
      "className": "grvsc-container quiet-light grvsc-ps-tuw09S",
      "data-language": "js",
      "data-index": "0"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-3 grvsc-tZ0ymb-i grvsc-tuw09S-4"
            }}>{`// --- hooks ---`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-3 grvsc-tZ0ymb-i grvsc-tuw09S-4"
            }}>{`// check for dark mode`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`useEffect`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`()`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-11"
            }}>{`=>`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-10 grvsc-tuw09S-11"
            }}>{`if`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` (`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`localStorage`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`getItem`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-4 grvsc-tuw09S-8"
            }}>{`dark`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`) `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-15"
            }}>{`===`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-4 grvsc-tuw09S-8"
            }}>{`dark`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-15"
            }}>{`||`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`    (`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`localStorage`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`getItem`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-4 grvsc-tuw09S-8"
            }}>{`dark`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`) `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-15"
            }}>{`!==`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-4 grvsc-tuw09S-8"
            }}>{`light`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-15"
            }}>{`&&`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`window`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`matchMedia`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-4 grvsc-tuw09S-8"
            }}>{`(prefers-color-scheme: dark)`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-11"
            }}>{`matches`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`)`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`  ) `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`document`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`body`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`classList`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`add`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-4 grvsc-tuw09S-8"
            }}>{`dark`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`)`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`},`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` [])`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-3 grvsc-tZ0ymb-i grvsc-tuw09S-4"
            }}>{`// --- functions ---`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-11"
            }}>{`const`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`toggleDarkMode`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-15"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`()`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-11"
            }}>{`=>`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-11"
            }}>{`const`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`isBodyDark`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-15"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`document`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`body`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`classList`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`contains`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-4 grvsc-tuw09S-8"
            }}>{`dark`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`)`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`document`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`body`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`classList`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`toggle`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-4 grvsc-tuw09S-8"
            }}>{`dark`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`)`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-10 grvsc-tuw09S-11"
            }}>{`if`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` (`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`isBodyDark`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`) `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`localStorage`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`setItem`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-4 grvsc-tuw09S-8"
            }}>{`dark`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-4 grvsc-tuw09S-8"
            }}>{`light`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`)`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-10 grvsc-tuw09S-11"
            }}>{`else`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`localStorage`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`setItem`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-4 grvsc-tuw09S-8"
            }}>{`dark`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-4 grvsc-tuw09S-8"
            }}>{`dark`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`)`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`}`}</span></span></span></code></pre>
    <p>{`The useEffect hook is to check if the user already has a preference either by previously visiting or having prefers-color-scheme set to dark on their device.`}</p>
    <p>{`I hope you got something from this quick post and if you have anything to add feel free to send me a message with your best Tailwind CSS tips!`}</p>

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
  .quiet-light { background-color: #F5F5F5; }
  .quiet-light .grvsc-tZ0ymb-b { font-weight: bold; }
  .quiet-light .grvsc-tZ0ymb-i { font-style: italic; }
  .quiet-light .grvsc-tZ0ymb-3 { color: #AAAAAA; }
  .quiet-light .grvsc-tZ0ymb-9 { color: #AA3731; }
  .quiet-light .grvsc-tZ0ymb-1 { color: #333333; }
  .quiet-light .grvsc-tZ0ymb-6 { color: #777777; }
  .quiet-light .grvsc-tZ0ymb-7 { color: #7A3E9D; }
  .quiet-light .grvsc-tZ0ymb-10 { color: #4B69C6; }
  .quiet-light .grvsc-tZ0ymb-4 { color: #448C27; }
  .quiet-light .grvsc-line-highlighted::before {
    background-color: var(--grvsc-line-highlighted-background-color, rgba(0, 0, 0, 0.05));
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, rgba(0, 0, 0, 0.2));
  }
  body[class=dark] .grvsc-ps-tuw09S {
    background-color: #1e1e1e;
    color: #c5c8c6;
  }
  body[class=dark] .grvsc-ps-tuw09S .grvsc-tuw09S-4 { color: #9A9B99; }
  body[class=dark] .grvsc-ps-tuw09S .grvsc-tuw09S-10 { color: #CE6700; }
  body[class=dark] .grvsc-ps-tuw09S .grvsc-tuw09S-1 { color: #C5C8C6FF; }
  body[class=dark] .grvsc-ps-tuw09S .grvsc-tuw09S-11 { color: #9872A2; }
  body[class=dark] .grvsc-ps-tuw09S .grvsc-tuw09S-3 { color: #6089B4; }
  body[class=dark] .grvsc-ps-tuw09S .grvsc-tuw09S-8 { color: #9AA83A; }
  body[class=dark] .grvsc-ps-tuw09S .grvsc-tuw09S-15 { color: #676867; }
  body[class=dark] .grvsc-ps-tuw09S .grvsc-line-highlighted::before {
    background-color: var(--grvsc-line-highlighted-background-color, rgba(255, 255, 255, 0.1));
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, rgba(255, 255, 255, 0.5));
  }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      